<template>
  <div class="d-flex" v-if="Items">
    <v-card
      flat
      outlined
      class="rounded-lg rounded-0 ma-1"
      :style="
        IsinTheCart == true
          ? { border: '1px solid' + btnColor }
          : 'global-card9'
      "
      :class="IsinTheCart == true ? 'global-cardSelected' : 'global-card9'"
    >
      <v-list-item three-line class="">
        <div class="mr-3 pa-4">
          <!-- <v-img
            class="global-img7"
            :src="
              require('../../assets/Ofertas/' +
                Items.compania_descripcion +
                '.png')
            "
          /> -->
        </div>
        <div>
          <div class="mt-3">
            <div class="global-itemtitle mt-5">
              {{ Items.plan_descripcion }}
            </div>
          </div>
          <div class="mt-1">
            <p class="global-itemsubtitle">
              {{ Items.compania_descripcion }}
            </p>
          </div>
          <!-- :style="{'color': btnColor}" -->
          <div class="global-price2">
            {{ Items.moneda_local.precio | moneyFormat }}
          </div>
        </div>
      </v-list-item>

      <v-card class="global-btnbase" flat>
        <v-layout>
          <v-flex lg6>
            <v-btn
              text
              outlined
              block
              :color="btnColor"
              class="global-btnStyle rounded-bl-lg text-capitalize"
              @click="VerMas(Items)"
            >
              <v-icon small class="mr-2">mdi-plus-circle-outline</v-icon> Ver
              más
            </v-btn>
          </v-flex>

          <v-flex>
            <v-btn
              v-if="!IsinTheCart"
              @click="actionAdd(Items)"
              :loading="loader"
              :color="btnColor"
              text
              outlined
              block
              class="global-btnStyle rounded-br-lg text-capitalize"
            >
              <v-icon small class="mr-2">mdi-cart-outline</v-icon> Agregar
            </v-btn>

            <v-btn
              v-if="IsinTheCart"
              @click="deleteItem(Items)"
              :loading="deleteloader"
              :color="btnColor"
              text
              outlined
              block
              class="global-btnStyle rounded-br-lg text-capitalize"
            >
              <v-icon small class="mr-2">mdi-delete-outline</v-icon> Quitar
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { cartItem } from "../../Mixins/CartComponentMixin";
export default {
  mixins: [cartItem],
  props: {
    Items: {
      type: [Object],
    },
    VerMas: {
      type: Function,
      default(Items) {
        return Items;
      },
    },
  },
  data() {
    return {
      cart: [],
      loader: false,
      deleteloader: null,
      IsinTheCart: false,
    };
  },
  methods: {
    actionAdd(item) {
      this.loader = true;

      const form = {
        item: "",
        plan: item.plan,
      };

      if (item.plan_descripcion.charAt(0) === "a") {
        form.item = "adicional";
      } else {
        form.item = "complementario";
      }

      axios({
        url: `carrito/${this.Propuesta.numero_propuesta}`,
        method: "POST",
        data: form,
      })
        .then((response) => {
          if (response) {
            this.$store.dispatch("GetCartItems");
            this.$store.commit("SET_items");
            this.IsinTheCart = true;
          }
        })
        .catch((err) => {
          if (err) this.loader = false;
        });
    },
  },
  watch: {
    Cart() {
      const it = this.Items;
      this.loader = false;
      this.deleteloader = false;
      if (this.Cart.length > 0) {
        var index = this.Cart.findIndex(function (o) {
          return o.plan === it.plan;
        });
        if (index !== -1) {
          this.IsinTheCart = true;
        } else {
          this.IsinTheCart = false;
        }
      }
      this.$emit("loadingcard");
    },
  },
  computed: {
    ...mapGetters("Users", ["getBotonColor"]),
    btnColor() {
      return this.getBotonColor;
    },
  },
};
</script>

<style scoped>
@import "../../assets/CSS/ServiceStyle.css";
.v-btn {
  border-radius: 0px;
}
</style>
