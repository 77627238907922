<template>
  <div class="d-flex">
    <div class="d-flex">
      <v-fade-transition>
        <v-alert
          v-if="show"
          dense
          class="global-alert d-flex align-center justify-center rounded-lg"
        >
          <span> {{ message }} {{ datas | formatDate }} </span>
        </v-alert>
      </v-fade-transition>
    </div>
  </div>
</template>
<script>
export default {
  props: ["show", "message", "date"],
  data() {
    return {
      alert: false,
      addToCart: 0,
      datas: null,
    };
  },
  watch: {
    date() {
      this.datas = this.date.cotizacion.fecha_creacion;
    },
  },
};
</script>

<style scoped>
@import "../../assets/CSS/ServiceStyle.css";
.iconStyle {
  font-size: 32px;
  color: #0077c8;
}
.badge {
  cursor: pointer;
}
</style>
