<template>
  <v-layout wrap class="d-flex justify-center">
    <v-flex lg12 md12 xl11>
      <div class="d-flex">
        <p class="global-title24 result-text mb-10 mt-3">
          Productos complementarios
        </p>
        <v-spacer></v-spacer>
        <div class="d-flex">
          <AlertAdded
            :show="addedToCart"
            class="mb-2"
            message="Se agregó un producto complementario en el carrito"
          />
          <v-btn :color="btnColor" text to="/carrito" class="mr-2 mt-2">
            <v-badge
              overlap
              :color="btnColor"
              :content="Cart.length"
              :value="Cart.length"
              offset-x="25"
              offset-y="14"
            >
              <i class="bi bi-cart" style="font-size: 34px" />
            </v-badge>
          </v-btn>
        </div>
      </div>
    </v-flex>
    <v-flex v-if="loadingcards" class="d-flex justify-center">
      <v-card flat v-for="index in 4" :key="index" class="ma-2">
        <v-skeleton-loader
          class="ma-3"
          width="250"
          height="150px"
          type="card"
        />
      </v-card>
    </v-flex>
    <v-flex v-if="!loadingcards" lg12 md12 xl11>
      <v-card class="mt-4 d-flex flex-wrap" flat>
        <v-slide-group multiple show-arrows>
          <v-slide-item class="mx-6" v-for="(prodItem, i) in AllItems" :key="i">
            <v-flex>
              <SecondProduct :Items="prodItem" :VerMas="VerMas" />
            </v-flex>
          </v-slide-item>
        </v-slide-group>
      </v-card>
      <v-btn
        depressed
        class="global-btn global-pbtn text-capitalize mt-10 float-right mr-11"
        @click="goCarrito"
        :color="btnColor"
        >Ir al carrito</v-btn
      >
      <Modal :open="modaldetails" :Infor="VerMasItem" :closeBtn="closeBtn" />
    </v-flex>
  </v-layout>
</template>
<script>
import SecondProduct from "@/components/Cards/Second-Product-card";
import Modal from "@/components/Modal/Modal-Detail";
import AlertAdded from "@/components/Alert/Alert-addToCart";
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      data: [],
      content: "",
      VerMasItem: "",
      AllItems: [],
      modaldetails: false,
      loadingcards: false,
    };
  },
  components: {
    SecondProduct,
    AlertAdded,
    Modal,
  },
  created() {
    this.goCarrito();
  },
  methods: {
    VerMas(value) {
      this.modaldetails = !this.modaldetails;
      this.VerMasItem = value;
    },
    closeBtn() {
      this.modaldetails = !this.modaldetails;
    },
    goCarrito() {
      this.$router.push({
        name: "ShoppingCart",
        params: { params: this.$route.params },
      });
    },
  },

  computed: {
    ...mapGetters("Users", ["getBotonColor", "getIconoColor"]),
    Cart() {
      return this.$store.getters.Cart;
    },
    addedToCart() {
      return this.$store.getters.addedToCart;
    },
    Propuesta() {
      return this.$store.getters.propuesta;
    },
    btnColor() {
      return this.getBotonColor ? this.getBotonColor : "rgb(0, 119, 200)";
    },
    iconColor() {
      return this.getIconoColor;
    },
  },

  mounted() {
    this.loadingcards = true;
    axios({
      url: `carrito/${this.Propuesta.numero_propuesta}/complementarios`,
      method: "GET",
    }).then((res) => {
      window.scrollTo(0, 0);
      //   this.isloading = false;

      this.AllItems = res.data.data.planes_complementarios;
      this.loadingcards = false;
    });
  },
};
</script>

<style scoped>
@import "../../../../assets/CSS/ServiceStyle.css";
</style>
